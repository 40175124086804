/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import LoginPage from './pages/AuthPages/LoginPage'
import NotFoundPage from './pages/NotFoundPage'
import RegisterPage from './pages/AuthPages/RegisterPage'
import { ROUTES } from './resources/routes-constants'
import './styles/main.sass'
import RequestResetPasswordPage from './pages/AuthPages/RequestResetPasswordPage'
import ResetPasswordPage from './pages/AuthPages/ResetPasswordPage'
import ConfirmAccountPage from './pages/AuthPages/ConfirmAccountPage'
import { useSelector } from 'react-redux'
import { Reducers, ReducerUser } from './types/reducers'
import HomePage from './pages/HomePage'
import Sidebar from './components/Sidebar/Sidebar'
import Header from './components/Header/Header'
import CollapsableSidebar from './components/CollapsableSidebar/CollapsableSidebar'
import { useDispatch } from 'react-redux'
import RadiosListPage from './pages/RadiosPage/RadiosListPage'
import RadioDetailPage from './pages/RadiosPage/RadioDetailPage'
import ShowDetailPage from './pages/ShowsPages/ShowDetailPage'
import FrequenciesPage from './pages/FrequenciesPage'
import NetworksListPage from './pages/NetworksPages/NetworksListPage'
import NetworksDetailPage from './pages/NetworksPages/NetworkDetailPage'
import DabListPage from './pages/DabPages/DabListPage'
import DabDetailPage from './pages/DabPages/DabDetailPage'
import UserPage from './pages/UserPage'
import PlayerDetailPage from './pages/PlayerPage/PlayerDetailPage'
import VideoPlayer from './components/MediaPlayer/VideoPlayer'
import { setCollapsedBar, setCurrentLanguage } from './store/actions/graphic'
import i18n, { use } from 'i18next'
import SettingsPage from './pages/SettingsPages/SettingsPage'
import InteractiveMUXPage from './pages/InteractiveMUXPage'
import CreateRadioTab from './pages/RadiosPage/tabs/CreateRadioTab'
import {
    fetchUserData,
    fetchRadios,
    fetchNetworks,
    fetchFrequencies,
    fetchDab,
    fetchInteractiveMux,
    fetchRadioTypes,
    fetchCountries,
    fetchCategories,
    fetchRadioTags,
    fetchStates,
    fetchRoles,
    fetchNotifications,
} from './store/actions/thunk_actions'
import { logoutUser } from './store/actions/user'
import FmWorldAxios from './utility/FmWorldAxios'
import FmWorldMultipartAxios from './utility/FmWorldMultipartAxios'
import { getAuth } from 'firebase/auth'
import BundlesTab from './pages/NetworksPages/tabs/BundlesTab'
import ApiTab from './pages/NetworksPages/tabs/ApiTab'
import PlayersTab from './pages/NetworksPages/tabs/PlayersTab'
import RadiosTab from './pages/NetworksPages/tabs/RadiosTab'
import UsersTab from './pages/NetworksPages/tabs/UsersTab'
import { setSelectedNetwork } from './store/actions/data'
import { Network } from './types/data'
import PageSidebarContainer from './components/PageSidebarContainer'
import CardContainer from './components/CardContainer/CardContainer'
import CustomAxios from './utility/CustomAxios'
import PendingElementsTab from './pages/NetworksPages/tabs/PendingElementsTab'
import PaymentsPage from './pages/NetworksPages/tabs/PaymentsPage'
import PendingElementsAdminView from './pages/NetworksPages/tabs/PendingElementsAdminView'

const RootComponent: React.FC = () => {
    const user = useSelector<Reducers, ReducerUser>((state) => state.user)
    const [loadingInitial, setLoadingInitial] = useState(false)
    const dispatch = useDispatch()
    const networkId = useSelector<Reducers>((state) => state.selectedNetwork)
    // console.log('networkId', networkId)

    useEffect(() => {
        if (user.user && user.user && user.user.language) {
            void i18n.changeLanguage(user.user.language)
            dispatch(setCurrentLanguage(user.user.language))
        }
    }, [user.user])

    const switchBaseName = () => {
        switch (process.env.REACT_APP_RELEASE_ENV) {
            case 'development':
                return process.env.PUBLIC_URL
            case 'staging':
                return '/'
            case 'production':
                return '/'
        }
    }

    const fetchInitialData = async () => {
        setLoadingInitial(true)
        try {
            if (user.user) {
                const token = user.user.authToken
                FmWorldAxios.defaults.headers.common.Authorization = `Bearer ${token}`
                FmWorldMultipartAxios.defaults.headers.common.Authorization = `Bearer ${token}`
                CustomAxios.defaults.headers.common.Authorization = `Bearer ${token}`
                const auth = getAuth()
                if (auth) {
                    await dispatch(fetchUserData(user.user.id))
                    await dispatch(fetchRadios())
                    await dispatch(fetchNetworks())
                    await dispatch(fetchFrequencies())
                    await dispatch(fetchDab())
                    await dispatch(fetchInteractiveMux())
                    await dispatch(fetchRadioTypes())
                    await dispatch(fetchCountries())
                    await dispatch(fetchCategories())
                    await dispatch(fetchRadioTags())
                    await dispatch(fetchStates())
                    await dispatch(fetchRoles())
                    await dispatch(fetchNotifications())
                    if (window.innerWidth < 1499) {
                        dispatch(setCollapsedBar(true))
                    }
                } else {
                    dispatch(logoutUser())
                }
            }
        } catch (error) {
            console.log(error)
        }
        setLoadingInitial(false)
    }

    useEffect(() => {
        if (user.user) {
            // FmWorldAxios.defaults.headers.common.Authorization = `Bearer ${token}`
            // FmWorldMultipartAxios.defaults.headers.common.Authorization = `Bearer ${token}`
            // void dispatch(fetchUserData(user.user.id))
            void fetchInitialData()
            dispatch(setSelectedNetwork(user.user?.networks[0] as unknown as Network))
        }
    }, [])

    useEffect(() => {
        if (user.user && !loadingInitial) {
            void fetchInitialData()
        }
    }, [user.user])

    return (
        <Router basename={switchBaseName()}>
            {user.user ? (
                <>
                    <Header />
                    <Sidebar />
                    <CollapsableSidebar />
                    <VideoPlayer />
                </>
            ) : null}
            <Routes>
                {user.user ? (
                    <>
                        <Route path="*" element={<NotFoundPage />} />
                        <Route path={ROUTES.HOMEPAGE_ROUTE} element={<HomePage />} />
                        <Route path={ROUTES.SETTINGS_ROUTE} element={<SettingsPage />} />
                        <Route path={ROUTES.FAQ_ROUTE} element={<HomePage />} />
                        <Route path={ROUTES.RADIO_LIST_ROUTE} element={<RadiosListPage />} />
                        <Route path={`${ROUTES.RADIO_DETAIL_ROUTE}:id`} element={<RadioDetailPage />} />
                        <Route path={`${ROUTES.SHOW_DETAIL_ROUTE}:id`} element={<ShowDetailPage />} />
                        <Route path={ROUTES.FREQUENCY_LIST_ROUTE} element={<FrequenciesPage />} />
                        <Route path={ROUTES.NETWORK_LIST_ROUTE} element={<NetworksListPage />} />
                        <Route path={`${ROUTES.NETWORK_DETAIL_ROUTE}:id`} element={<NetworksDetailPage />} />
                        <Route path={`${ROUTES.PLAYER_DETAIL_ROUTE}:id`} element={<PlayerDetailPage />} />
                        <Route path={ROUTES.DAB_LIST_ROUTE} element={<DabListPage />} />
                        <Route path={ROUTES.INTERACTIVE_MUX_ROUTE} element={<InteractiveMUXPage />} />
                        <Route path={`${ROUTES.DAB_DETAIL_ROUTE}:id`} element={<DabDetailPage />} />
                        <Route path={ROUTES.USER_ROUTE} element={<UserPage />} />
                        <Route
                            path={ROUTES.CREATE_NEW_RADIO_ROUTE}
                            element={
                                <PageSidebarContainer>
                                    <CardContainer>
                                        <CreateRadioTab />
                                    </CardContainer>
                                </PageSidebarContainer>
                            }
                        />
                        <Route
                            path={ROUTES.RADIO_PENDING_LIST_ROUTE}
                            element={
                                <PageSidebarContainer>
                                    <CardContainer>
                                        <PendingElementsAdminView permissions={user.user.grants} />
                                    </CardContainer>
                                </PageSidebarContainer>
                            }
                        />
                        <Route
                            path={ROUTES.NETWORKS_PENDING_ELEMENTS}
                            element={
                                <PageSidebarContainer>
                                    <CardContainer>
                                        <PendingElementsTab permissions={user.user.grants} />
                                    </CardContainer>
                                </PageSidebarContainer>
                            }
                        />
                        <Route
                            path={ROUTES.NETWORKS_BUNDLES_ROUTE}
                            element={
                                <PageSidebarContainer>
                                    <CardContainer>
                                        <BundlesTab permissions={user.user.grants} />
                                    </CardContainer>
                                </PageSidebarContainer>
                            }
                        />
                        <Route
                            path={ROUTES.NETWORKS_USERS_ROUTE}
                            element={
                                <PageSidebarContainer>
                                    <CardContainer>
                                        <UsersTab />
                                    </CardContainer>
                                </PageSidebarContainer>
                            }
                        />
                        <Route
                            path={ROUTES.NETWORKS_PLAYERS_ROUTE}
                            element={
                                <PageSidebarContainer>
                                    <CardContainer>
                                        <PlayersTab permissions={user.user.grants} />
                                    </CardContainer>
                                </PageSidebarContainer>
                            }
                        />
                        <Route
                            path={ROUTES.NETWORKS_RADIO_ROUTE}
                            element={
                                <PageSidebarContainer>
                                    <CardContainer>
                                        <RadiosTab permissions={user.user.grants} />
                                    </CardContainer>
                                </PageSidebarContainer>
                            }
                        />
                        <Route
                            path={ROUTES.NETWORKS_API_ROUTE}
                            element={
                                <PageSidebarContainer>
                                    <CardContainer>
                                        <ApiTab permissions={user.user.grants} />
                                    </CardContainer>
                                </PageSidebarContainer>
                            }
                        />
                        <Route
                            path={ROUTES.NETWORKS_PAYMENTS_ROUTE}
                            element={
                                <PageSidebarContainer>
                                    <CardContainer>
                                        <PaymentsPage />
                                    </CardContainer>
                                </PageSidebarContainer>
                            }
                        />
                    </>
                ) : (
                    <>
                        <Route path={ROUTES.HOMEPAGE_ROUTE} element={<LoginPage />} />
                        <Route path={ROUTES.LOGIN_ROUTE} element={<LoginPage />} />
                        <Route path={ROUTES.REGISTER_ROUTE} element={<RegisterPage />} />
                        <Route path={ROUTES.REQUEST_RESET_PASSWORD_ROUTE} element={<RequestResetPasswordPage />} />
                        <Route path={ROUTES.RESET_PASSWORD_ROUTE} element={<ResetPasswordPage />} />
                        <Route path={ROUTES.CONFIRM_ACCOUNT_ROUTE} element={<ConfirmAccountPage />} />
                    </>
                )}
                {/** --- END AUTH ROUTES --- */}
            </Routes>
        </Router>
    )
}

export default RootComponent
