/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button/Button'
import Link from '../../components/Link/Link'
import TextInput from '../../components/TextInput/TextInput'
import { ROUTES } from '../../resources/routes-constants'
import { setLoggedUserData } from '../../store/actions/user'
import './../../styles/pages/LoginPage.sass'
import { ResetPasswordPageParams } from './RequestResetPasswordPage'
import StaticContentRegisterPage from '../../components/StaticContentRegisterPage'
import { COLORS } from '../../resources/colors'
import LoadingModal from '../../components/Modals/LoadingModal'
import { useTranslation } from 'react-i18next'
import { emailRegEx, passwordRegEx } from '../../utility/functions'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../utility/configFirebase'
import { getCurrentFMWorldUser, logFmWorldUser } from '../../resources/api-constants'
import FmWorldAxios from '../../utility/FmWorldAxios'
import { setSelectedNetwork } from '../../store/actions/data'
import CustomAxios from '../../utility/CustomAxios'
import { AUTH_PROVIDER, performSocialLogin } from '../../services/firebase'
import GoogleLogo from '../../resources/images/google-logo.png'
import AppleLogo from '../../resources/images/apple-logo.png'

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loginError, setLoginError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const routeParams = useMemo(() => {
        const params = {
            state: {
                userEmail: email,
            } as ResetPasswordPageParams,
        }
        return params
    }, [email])

    const logUser = async () => {
        setIsLoading(true)
        try {
            const user = (await signInWithEmailAndPassword(auth, email, password).then((res) => res.user)) as any

            const userToken = user.accessToken

            await logFmWorldUser(userToken)
            const userInfo = await getCurrentFMWorldUser(userToken)

            FmWorldAxios.defaults.headers.common.Authorization = `Bearer ${userToken}`
            CustomAxios.defaults.headers.common.Authorization = `Bearer ${userToken}`

            if (!userInfo.networks || userInfo.networks.length === 0) {
                setLoginError(t('errors.no-networks-error').toString())
                setIsLoading(false)
                return
            }

            dispatch(
                setLoggedUserData({
                    ...userInfo,
                    authToken: userToken,
                    refreshToken: user.stsTokenManager.refreshToken,
                })
            )
            dispatch(setSelectedNetwork(userInfo.networks[0]))
            navigate(`${ROUTES.HOMEPAGE_ROUTE}`)
        } catch (error) {
            console.error(error)
            setLoginError(t('general.error').toString())
        }
        setIsLoading(false)
    }

    const socialLogin = async (provider: AUTH_PROVIDER) => {
        try {
            const loginResult = await performSocialLogin(provider)
            if (!loginResult) return

            const userToken = await loginResult.getIdToken(false)

            FmWorldAxios.defaults.headers.common.Authorization = `Bearer ${userToken}`
            CustomAxios.defaults.headers.common.Authorization = `Bearer ${userToken}`

            await logFmWorldUser(userToken)
            const userInfo = await getCurrentFMWorldUser(userToken)

            if (!userInfo.networks || userInfo.networks.length === 0) {
                setLoginError(t('errors.no-networks-error').toString())
                setIsLoading(false)
                return
            }

            dispatch(setLoggedUserData({ ...userInfo, authToken: userToken }))

            dispatch(setSelectedNetwork(userInfo.networks[0]))
            navigate(`${ROUTES.HOMEPAGE_ROUTE}`)
        } catch (error) {
            console.error(error)
            setLoginError(t('general.error').toString())
        }
    }

    return (
        <div className="u-full-page-container u-row-page" style={{ gap: 0 }}>
            <div className="c-login-interactive-panel">
                <div className="c-login-interactive-panel__main-panel">
                    <form
                        className="o-login-inputs-container"
                        onSubmit={async (event) => {
                            event.preventDefault()
                            if (!isLoading && email && password) {
                                await logUser()
                            }
                        }}
                    >
                        <p className="u-medium-text u-bold-text">{t('auth.login')}</p>
                        <div className="social-buttons-container">
                            <button
                                className="base-btn form-btn"
                                onClick={() => {
                                    void socialLogin(AUTH_PROVIDER.GOOGLE)
                                }}
                            >
                                <img className="form-btn__img" src={GoogleLogo} alt="Google" />
                                {t('auth.login_with_google')}
                            </button>
                            <button
                                className="base-btn form-btn"
                                onClick={() => {
                                    void socialLogin(AUTH_PROVIDER.APPLE)
                                }}
                            >
                                <img className="form-btn__img" src={AppleLogo} alt="Apple" />
                                {t('auth.login_with_apple')}
                            </button>
                        </div>
                        <div className="divider" />
                        <div className="u-normal-text">
                            {t('auth.no_have_account')}{' '}
                            <Link
                                label={t('auth.register')}
                                underline="always"
                                type="accent"
                                target="internal"
                                targetUrl={{ url: ROUTES.REGISTER_ROUTE }}
                            />
                        </div>
                        <TextInput
                            value={email}
                            placeholder={t('auth.email')}
                            borderColor={email && !emailRegEx.test(email) ? COLORS.redDelete : ''}
                            onTextChange={(newValue) => setEmail(newValue)}
                        />
                        <TextInput
                            value={password}
                            placeholder={t('auth.password')}
                            type="password"
                            borderColor={password && !passwordRegEx.test(password) ? COLORS.redDelete : ''}
                            onTextChange={(newValue) => setPassword(newValue)}
                        />
                        <p className="u-normal-text u-bold-text">{t('auth.terms_accept')}</p>
                        <Link
                            label={t('auth.viewThePrivacyPolicy')}
                            underline="always"
                            color={COLORS.purple}
                            type="accent"
                            target="external"
                            href="https://www.fm-world.it/privacy/"
                        />
                        {loginError && (
                            <p className="u-normal-text u-bold-text u-error-text">
                                {loginError}.
                            </p>
                        )}
                        <Button
                            label={t('auth.logon')}
                            type="submit"
                            onPress={async () => {
                                if (
                                    !isLoading &&
                                    email &&
                                    emailRegEx.test(email) &&
                                    password &&
                                    passwordRegEx.test(password)
                                ) {
                                    await logUser()
                                }
                            }}
                            disabled={!email || !password}
                        />
                        <Link
                            label={t('auth.forgot_password')}
                            underline="always"
                            color={COLORS.purple}
                            type="hover"
                            target="internal"
                            targetUrl={{ url: ROUTES.REQUEST_RESET_PASSWORD_ROUTE, params: routeParams }}
                        />
                    </form>
                </div>
            </div>
            <LoadingModal isVisible={isLoading} text={t('loadings.logging_in')} />
            <StaticContentRegisterPage />
        </div>
    )
}

export default LoginPage
