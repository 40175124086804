export const ROUTES = {
    HOMEPAGE_ROUTE: '/',
    RADIO_LIST_ROUTE: '/radios',
    RADIO_DETAIL_ROUTE: '/radio/',
    SHOW_DETAIL_ROUTE: '/show/',
    NETWORK_LIST_ROUTE: '/networks',
    NETWORK_DETAIL_ROUTE: '/network/',
    FREQUENCY_LIST_ROUTE: '/frequencies',
    DAB_LIST_ROUTE: '/dab',
    INTERACTIVE_MUX_ROUTE: '/interactive_mux',
    DAB_DETAIL_ROUTE: '/dab/',
    LOGIN_ROUTE: '/login',
    REGISTER_ROUTE: '/register',
    RADIO_PENDING_LIST_ROUTE: '/pending-requests',
    REQUEST_RESET_PASSWORD_ROUTE: '/request-reset-password',
    RESET_PASSWORD_ROUTE: '/reset-password',
    CONFIRM_ACCOUNT_ROUTE: '/confirm-account',
    NETWORKS_PENDING_ELEMENTS: '/pending-elements',
    SETTINGS_ROUTE: '/settings',
    FAQ_ROUTE: '/faq',
    ANALYTICS_ROUTE: '/analytics',
    USER_ROUTE: '/user',
    PLAYER_DETAIL_ROUTE: '/player/',
    CREATE_NEW_RADIO_ROUTE: '/create-radio',
    NETWORKS_BUNDLES_ROUTE: '/networks-bundles',
    NETWORKS_USERS_ROUTE: '/networks-users',
    NETWORKS_PLAYERS_ROUTE: '/networks-players',
    NETWORKS_RADIO_ROUTE: '/networks-radio',
    NETWORKS_API_ROUTE: '/networks-api',
    NETWORKS_PAYMENTS_ROUTE: '/networks-payments',
}
